import React from "react"

const ProfileBanner = () => {
  return (
    <div className="profile-banner">
      <div className="container">
        <div className="profile-banner-inner">
          <h4>
            J'ai travaillé comme Visual designer, Full-Stack developer, UI /UX designer et researcher où j'ai eu une expérience professionnelle très internationale.<br />
            <br /> Mon nom est{" "}
            <span className="semi-bold"> Raphaël M.</span>, j'ai près de 5 ans d'expérience dans le design et la gestion de la communication.
            Je suis convaincu que mon expérience me permettra de contribuer au développement de votre entreprise.
            <br />
            <br />
            Je propose une solution complète. Si vous voulez que je travail pour vous, envoyez-moi un e-mail :
            <span className="semi-bold"> raphael@paaolms.com</span>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default ProfileBanner
